import { GameContextPreparer, World } from "./index";
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Euler,
  PointLight,
  Vector3,
} from "three";
import { createStaticEntity } from "../entity/staticEntity";
import { createGoalBox } from "../entity/goalBox";
import { createCookingPot } from "../entity/cookingPot";
import { createMug } from "../entity/mug";
import { createCookingPan } from "../entity/cookingPan";
import { createGoalSphere } from "../entity/goalSphere";

const loadBaseWorld: GameContextPreparer = async (gameContext) => {
  gameContext.scene.add(new AmbientLight(new Color("white"), 0.5));
  const pointLight = new PointLight(new Color("lightsalmon"), 0.25, 10, 1.5);
  pointLight.position.set(0, 3, 1);
  gameContext.scene.add(pointLight);

  const directionalLight = new DirectionalLight(new Color("white"), 0.75);
  directionalLight.position.set(10, 10, 10);
  directionalLight.lookAt(0, 0, 0);
  gameContext.scene.add(directionalLight);

  gameContext.addEntity(
    await createStaticEntity(gameContext, { modelFile: "kitchen.glb" }),
  );
};

export const kitchen: World = {
  worldId: "kitchen",
  lives: 5,
  soundFile: "washing-dishes.mp3",
  soundVolume: 0.2,
  chapters: [
    {
      chapterId: "into-the-sink",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createCookingPan(gameContext, {
            position: new Vector3(-4.5, 1, -11.12),
            scale: new Vector3(0.5, 0.5, 0.5),
            rotation: new Euler(0, Math.PI, -0.7),
          }),
        );

        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-4.95, 1.01, -11.27),
          }),
        );
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-4.9, 1.01, -11.07),
          }),
        );
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-4.65, 0.9, -11.12),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(-4.55, 0.9, -11.12),
            scale: new Vector3(0.35, 0.15, 0.3),
          }),
        );

        // Set camera
        gameContext.camera.position.set(-5.07, 1.34, -10.34);
        gameContext.camera.lookAt(-4.63, 0.98, -11.12);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "onto-the-hot-plate",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createCookingPot(gameContext, {
            position: new Vector3(-5.95, 1.05, -11.27),
          }),
        );

        gameContext.addEntity(
          await createCookingPan(gameContext, {
            position: new Vector3(-5.65, 1.05, -10.97),
            rotation: new Euler(0, Math.PI * 0.25, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(-5.81, 0.9, -11.13),
            scale: new Vector3(0.5, 0.15, 0.47),
          }),
        );

        // Set camera
        gameContext.camera.position.set(-5.57, 1.34, -10.34);
        gameContext.camera.lookAt(-5.75, 0.9, -11.34);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "cooking-dinner",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createStaticEntity(gameContext, {
            modelFile: "cooking_pot.glb",
            position: new Vector3(-5.95, 0.95, -11.27),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(-5.95, 0.95, -11.27),
            scale: new Vector3(0.11, 0.15, 0.11),
          }),
        );

        // Set camera
        gameContext.camera.position.set(-5.7, 1.24, -10.54);
        gameContext.camera.lookAt(-5.75, 0.9, -11.34);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "preparing-dinner-table",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createStaticEntity(gameContext, {
            modelFile: "table.glb",
            position: new Vector3(-5.25, 0.0, -9.27),
            scale: new Vector3(1.5, 1.5, 1.5),
          }),
        );

        const createPlateWithGoal = async (position: Vector3) => {
          gameContext.addEntity(
            await createStaticEntity(gameContext, {
              modelFile: "plate.glb",
              position,
            }),
          );

          gameContext.addEntity(
            await createGoalSphere(gameContext, {
              position: position.clone().add(new Vector3(0, 0.04, 0)),
              scale: new Vector3(0.11, 0.02, 0.11),
            }),
          );
        };

        // Front row
        await createPlateWithGoal(new Vector3(-4.8, 0.7, -9.6));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-5.0, 0.77, -9.5),
          }),
        );

        await createPlateWithGoal(new Vector3(-5.3, 0.7, -9.6));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-5.5, 0.77, -9.5),
          }),
        );

        await createPlateWithGoal(new Vector3(-5.8, 0.7, -9.6));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-6.0, 0.77, -9.5),
          }),
        );

        // Back row
        await createPlateWithGoal(new Vector3(-4.8, 0.7, -9.0));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-4.6, 0.77, -9.2),
          }),
        );

        await createPlateWithGoal(new Vector3(-5.3, 0.7, -9.0));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-5.1, 0.77, -9.2),
          }),
        );

        await createPlateWithGoal(new Vector3(-5.8, 0.7, -9.0));
        gameContext.addEntity(
          await createMug(gameContext, {
            position: new Vector3(-5.6, 0.77, -9.2),
          }),
        );

        // Set camera
        gameContext.camera.position.set(-5.57, 1.14, -10.34);
        gameContext.camera.lookAt(-5.25, 0.9, -9.27);
        gameContext.camera.updateMatrix();
      },
    },
  ],
};
