interface TurdImageProps {
  saturated: boolean;
}

const TurdImage = ({ saturated }: TurdImageProps) => {
  return (
    <img
      style={{ width: "2rem", height: "2rem" }}
      alt={"lives"}
      src={saturated ? "/images/turd.png" : "/images/turd_des.png"}
    />
  );
};

export interface LivesProps {
  lives: number;
  maxLives: number;
}

export const Lives = ({ lives, maxLives }: LivesProps) => {
  return (
    <div className={"d-flex justify-content-center gap-2"}>
      {Array.from({ length: lives }).map((_, index) => (
        <TurdImage key={index} saturated={true} />
      ))}
      {Array.from({ length: maxLives - lives }).map((_, index) => (
        <TurdImage key={index} saturated={false} />
      ))}
    </div>
  );
};
