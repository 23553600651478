import { Button, Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "./index";
import { ShareButtons } from "../component/ShareButtons";

export const HomeRoute = () => {
  const navigate = useNavigate();

  return (
    <Container
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{ height: "100%" }}
    >
      <h1 style={{ textAlign: "center" }}>
        <FormattedMessage id={"game.title"} />
      </h1>
      <Row style={{ width: "100%" }}>
        <Col />
        <Col xs={6}>
          <Button
            size={"lg"}
            className={"mt-4"}
            style={{ width: "100%", height: "5rem", fontSize: "2rem" }}
            onClick={() => navigate(routes.worldSelection.render())}
          >
            <FormattedMessage id={"start"} />
          </Button>
        </Col>
        <Col />
      </Row>
      <small
        style={{ textAlign: "center", display: "block", width: "100%" }}
        className={"text-muted mt-4"}
      >
        <FormattedMessage id={"use.fullscreen.on.mobile"} />
      </small>
      <div style={{ width: "100%" }} className={"mt-3"}>
        <ShareButtons />
      </div>
      <div
        style={{ width: "100%" }}
        className={"d-flex justify-content-center gap-2 mt-5"}
      >
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.privacyPolicy.render())}
        >
          <FormattedMessage id={"privacy.policy"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.attributions.render())}
        >
          <FormattedMessage id={"attributions"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.imprint.render())}
        >
          <FormattedMessage id={"imprint"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.disclaimer.render())}
        >
          <FormattedMessage id={"disclaimer"} />
        </Button>
      </div>
    </Container>
  );
};
