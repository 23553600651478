export const Background = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#070600",
      }}
    ></div>
  );
};
