import { useEffect } from "react";

export interface SwipeGesture {
  clientXStart: number;
  clientYStart: number;
  diffClientX: number;
  diffClientY: number;
  diffTime: number;
}

export type SwipeHandler = (gesture: SwipeGesture) => void;

export interface UseSwipeGestureProps {
  onSwipe: SwipeHandler;
}

export const useSwipeGesture = ({ onSwipe }: UseSwipeGestureProps) => {
  useEffect(() => {
    console.debug("UseSwipeGesture :: Registering event listeners");

    let clientXStart = 0;
    let clientYStart = 0;
    let timeStart = 0;

    const handleTouchStart = (e: TouchEvent) => {
      console.debug("UseSwipeGesture :: Handle touch start", e);

      clientXStart = e.changedTouches[0].clientX;
      clientYStart = e.changedTouches[0].clientY;
      timeStart = Date.now();
    };

    const handleMouseDown = (e: MouseEvent) => {
      console.debug("UseSwipeGesture :: Handle mouse down", e);

      clientXStart = e.clientX;
      clientYStart = e.clientY;
      timeStart = Date.now();
    };

    const handleTouchEnd = (e: TouchEvent) => {
      console.debug("UseSwipeGesture :: Handle touch end", e);

      const diffClientX = clientXStart - e.changedTouches[0].clientX;
      const diffClientY = clientYStart - e.changedTouches[0].clientY;
      const diffTime = Date.now() - timeStart;

      onSwipe({
        diffClientX,
        diffClientY,
        diffTime,
        clientXStart,
        clientYStart,
      });
    };

    const handleMouseUp = (e: MouseEvent) => {
      console.debug("UseSwipeGesture :: Handle mouse up", e);

      const diffClientX = clientXStart - e.clientX;
      const diffClientY = clientYStart - e.clientY;
      const diffTime = Date.now() - timeStart;

      onSwipe({
        diffClientX,
        diffClientY,
        diffTime,
        clientXStart,
        clientYStart,
      });
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("mouseup", handleMouseUp);

    // Clean up
    return () => {
      console.debug("UseSwipeGesture :: Unregistering event listeners");

      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [onSwipe]);
};
