import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import de from "./translation/de.json";
import en from "./translation/en.json";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import { PhysicsLoader } from "@enable3d/ammo-physics";
import { createGameContext } from "./gameContext";
import { GameContextContext } from "./context/GameContextContext";
import { IntlProvider } from "react-intl";
import { DebugContextProvider } from "./context/DebugContext";

// Translations
const resolveMessages = (locale: string) => {
  if (locale.startsWith("de")) {
    return de;
  }

  return en;
};

const init = () => {
  console.debug("index :: init");

  // Setup game context
  const gameContext = createGameContext();

  // Setup react
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );

  root.render(
    <StrictMode>
      <DebugContextProvider>
        <GameContextContext.Provider value={gameContext}>
          <IntlProvider
            messages={resolveMessages(navigator.language)}
            locale={navigator.language}
            defaultLocale={"en"}
          >
            <App />
          </IntlProvider>
        </GameContextContext.Provider>
      </DebugContextProvider>
    </StrictMode>,
  );
};

PhysicsLoader("/lib", init);
