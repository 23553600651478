import Image from "react-bootstrap/Image";

export interface FullScreenButtonProps {
  element: HTMLElement;
}

export const FullScreenButton = ({ element }: FullScreenButtonProps) => {
  const handleClick = () => {
    if (document.fullscreenElement) {
      void document.exitFullscreen();
    } else {
      void element.requestFullscreen({ navigationUI: "show" });
    }
  };

  return (
    <Image
      src={"/images/full_screen.png"}
      onClick={handleClick}
      style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
    />
  );
};
