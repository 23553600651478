const resolveLocale = () => {
  if (navigator.language.startsWith("de")) {
    return "de";
  }

  return "en";
};

export const fetchText = (text: string) => {
  const locale = resolveLocale();
  return fetch(`/texts/${text}/${locale}.txt`).then((result) => result.text());
};
