import { To } from "@remix-run/router/history";

export interface Route {
  path: string;
  render: (...params: any[]) => To;
}

export const routes = {
  home: {
    path: "/",
    render: () => "/",
  },
  worldSelection: {
    path: "/worlds",
    render: () => "/worlds",
  },
  playWorld: {
    path: "/worlds/:worldId",
    render: (worldId: string) => `/worlds/${worldId}`,
  },
  privacyPolicy: {
    path: "/privacy-policy",
    render: () => "/privacy-policy",
  },
  imprint: {
    path: "/imprint",
    render: () => "/imprint",
  },
  attributions: {
    path: "/attributions",
    render: () => "/attributions",
  },
  disclaimer: {
    path: "/disclaimer",
    render: () => "/disclaimer",
  },
} as const satisfies Record<string, Route>;
