import { GameContext } from "../gameContext";
import { restroom } from "./restroom";
import { kitchen } from "./kitchen";
import { soccerField } from "./soccerField";
import { space } from "./space";
import { isProduction } from "../environment";

export type GameContextPreparer = (gameContext: GameContext) => Promise<void>;

export interface Chapter {
  chapterId: string;
  prepareGameContext: GameContextPreparer;
}

export interface World {
  worldId: string;
  chapters: Chapter[];
  lives: number;
  soundFile: string;
  soundVolume?: number;
}

const devWorlds: World[] = [space];

export const worlds: World[] = [
  restroom,
  kitchen,
  soccerField,
  ...(!isProduction() ? devWorlds : []),
];
