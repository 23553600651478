import { GameContextPreparer, World } from "./index";
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Euler,
  PointLight,
  Vector3,
} from "three";
import { createStaticEntity } from "../entity/staticEntity";
import { createGoalSphere } from "../entity/goalSphere";
import { createToiletPaper } from "../entity/toiletPaper";
import { createWeirdDude } from "../entity/weirdDude";

const loadBaseWorld: GameContextPreparer = async (gameContext) => {
  gameContext.scene.add(new AmbientLight(new Color("white"), 0.5));
  const pointLight = new PointLight(new Color("lightsalmon"), 0.25, 10, 1.5);
  pointLight.position.set(0, 3, 1);
  gameContext.scene.add(pointLight);

  const directionalLight = new DirectionalLight(new Color("white"), 0.75);
  directionalLight.position.set(10, 10, 10);
  directionalLight.lookAt(0, 0, 0);
  gameContext.scene.add(directionalLight);

  gameContext.addEntity(
    await createStaticEntity(gameContext, { modelFile: "stage_1.glb" }),
  );

  // TP next to toilet
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(-0.87, 0.1, 2.7),
    }),
  );
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(-0.85, 0.28, 2.7),
    }),
  );
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(-0.87, 0.46, 2.71),
    }),
  );
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(-0.85, 0.64, 2.71),
    }),
  );

  // TP next to bucket
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(2.28, 0.1, 2.66),
    }),
  );
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(2.18, 0.1, 2.86),
    }),
  );
  gameContext.addEntity(
    await createToiletPaper(gameContext, {
      position: new Vector3(2.28, 0.28, 2.76),
    }),
  );
};

export const restroom: World = {
  worldId: "restroom",
  lives: 5,
  soundFile: "inside-toilet.mp3",
  soundVolume: 0.2,
  chapters: [
    {
      chapterId: "into-the-toilet",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        // Goal
        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(-1.26, 0.41, 2.25),
            scale: new Vector3(0.17, 0.15, 0.2),
          }),
        );

        // Set camera
        gameContext.camera.position.set(-1.24, 1.18, 1.5);
        gameContext.camera.lookAt(-1.23, 0.6, 2.61);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "into-the-bucket",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        // Goal
        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(2.68, 0.17, 2.66),
            scale: new Vector3(0.14, 0.3, 0.14),
          }),
        );

        // Set camera
        gameContext.camera.position.set(2.3, 0.9, 2);
        gameContext.camera.lookAt(2.68, 0.37, 2.66);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "into-the-sink",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        // That weird dude
        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(2.28, 0.95, 2.0),
            rotation: new Euler(0, Math.PI * 1.1, 0),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(2.62, 1.1, -0.42),
            scale: new Vector3(0.2, 0.1, 0.25),
          }),
        );

        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(2.62, 1.1, -1.45),
            scale: new Vector3(0.2, 0.1, 0.25),
          }),
        );

        // Set camera
        gameContext.camera.position.set(1.69, 1.49, -1.72);
        gameContext.camera.lookAt(2.56, 1.18, -1.34);
        gameContext.camera.updateMatrix();
      },
    },
    {
      chapterId: "into-the-bucket-again",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        // That weird dude
        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(2.1, 0.95, 2.0),
            rotation: new Euler(0, Math.PI * 0.7, 0),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalSphere(gameContext, {
            position: new Vector3(2.68, 0.17, 2.66),
            scale: new Vector3(0.14, 0.3, 0.14),
          }),
        );

        // Set camera
        gameContext.camera.position.set(2.2, 1.25, 0.45);
        gameContext.camera.lookAt(2.3, 0.8, 1.35);
        gameContext.camera.updateMatrix();
      },
    },
  ],
};
