import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface Debug {
  enabled: boolean;
}

export const DebugContext = createContext<Debug | null>(null);

export const useDebug = (): Debug => {
  const debug = useContext(DebugContext);

  if (debug === null) {
    throw new Error("Game context not provided");
  }

  return debug;
};

export const DebugContextProvider = (props: PropsWithChildren) => {
  const [enabled, setEnabled] = useState(false);

  const debug = useMemo<Debug>(() => ({ enabled }), [enabled]);

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === "F2") {
        setEnabled((visible) => !visible);
      }
    };

    window.addEventListener("keydown", keyDownListener);

    // Clean up
    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, [setEnabled]);

  return (
    <DebugContext.Provider value={debug}>
      {props.children}
    </DebugContext.Provider>
  );
};
