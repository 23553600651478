import {
  ActivationState,
  CollisionShape,
  createExtendedObject3DFromModel,
  createScoreCollisionCallback,
  Entity,
  EntityCreator,
} from "./index";

export const createCookingPan: EntityCreator = async (
  gameContext,
  entityProps,
) => {
  const object = await createExtendedObject3DFromModel(
    "cooking_pan.glb",
    entityProps,
  );
  const entity: Entity = {
    object,
    config: {
      shape: CollisionShape.CYLINDER,
      ignoreScale: true,
      offset: {
        y: -0.01,
      },
      radius: 0.15,
      height: 0.1,
      mass: 1.0,
    },
  };

  object.addEventListener("added", () => {
    // Update physic props
    object.body.ammo.setFriction(0.5);
    object.body.ammo.setRollingFriction(0.01);
    object.body.ammo.setDamping(0.02, 0.0);
    object.body.ammo.setRestitution(0.0);
    object.body.ammo.forceActivationState(ActivationState.ISLAND_SLEEPING);

    const scoreCallback = createScoreCollisionCallback(gameContext, 250);

    object.body.on.collision((otherObject, event) => {
      scoreCallback(otherObject, event);
    });
  });

  return entity;
};
