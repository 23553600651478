import { Background } from "../../component/Background";
import { Container, Spinner } from "react-bootstrap";

export const LoadingScreen = () => {
  return (
    <>
      <Background />
      <Container
        style={{ height: "100%" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    </>
  );
};
