import { PropsWithChildren } from "react";

export const TopButtonToolbar = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={"d-flex justify-content-start"}
      style={{ position: "absolute", top: "1rem", left: "1rem" }}
    >
      {children}
    </div>
  );
};
