import {
  ActivationState,
  CollisionShape,
  createExtendedObject3DFromModel,
  createScoreCollisionCallback,
  Entity,
  EntityCreator,
} from "./index";
import { Vector3 } from "three";
import { getUserData } from "./userData";
import { playSound } from "../sound";

export const createWeirdDude: EntityCreator = async (
  gameContext,
  entityProps,
) => {
  const object = await createExtendedObject3DFromModel(
    "man_standing.glb",
    entityProps,
  );
  const entity: Entity = {
    object,
    config: {
      shape: CollisionShape.BOX,
      height: 1.8,
      width: 0.8,
      depth: 0.2,
      mass: 40.0,
    },
  };

  object.addEventListener("added", () => {
    // Update physic props
    object.body.ammo.setFriction(0.5);
    object.body.ammo.setRollingFriction(0.1);
    object.body.ammo.setDamping(0.02, 0.05);
    object.body.ammo.setRestitution(0.0);
    object.body.ammo.forceActivationState(ActivationState.ISLAND_SLEEPING);

    const scoreCallback = createScoreCollisionCallback(gameContext, 500);

    let playingSound = false;

    // Score changes
    object.body.on.collision((otherObject, event) => {
      scoreCallback(otherObject, event);

      if (event === "start") {
        // Skip non-turd objects
        if (getUserData(otherObject)?.isTurd !== true) {
          return;
        }

        const speed = new Vector3(
          object.body.velocity.x,
          object.body.velocity.y,
          object.body.velocity.z,
        ).length();

        if (speed > 0.25 && !playingSound) {
          playingSound = true;
          playSound("oh-good.mp3", {
            volume: 0.75,
            playbackRate: 0.66 + Math.random() * 0.33,
            onEnded: () => (playingSound = false),
          });
        }
      }
    });
  });

  return entity;
};
