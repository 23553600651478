import { Object3D } from "three";

export interface UserData {
  isTurd?: boolean;
}

export const getUserData = (object: Object3D): UserData | undefined => {
  return object.userData as UserData | undefined;
};

export const setUserData = (
  object: Object3D,
  userData: UserData | undefined,
) => {
  (object as any).userData = userData;
};
