import {
  CollisionFlags,
  CollisionGroup,
  CollisionShape,
  EntityCreator,
} from "./index";
import { Color, Mesh, MeshBasicMaterial, SphereGeometry } from "three";
import { ExtendedObject3D } from "@enable3d/ammo-physics";
import { getUserData } from "./userData";

export const OCCUPATION_TIME = 1500;

const OCCUPIED_COLOR = new Color("#07e325");
const UNOCCUPIED_COLOR = new Color("#7f9175");

const goalGeometry = new SphereGeometry(1, 16, 16);

export const createGoalSphere: EntityCreator = async (
  gameContext,
  { scale, position },
) => {
  const goalMaterial = new MeshBasicMaterial({
    color: UNOCCUPIED_COLOR,
    transparent: true,
    opacity: 0.375,
  });

  const mesh = new Mesh(goalGeometry, goalMaterial);

  if (scale) {
    mesh.scale.set(scale.x, scale.y, scale.z);
  }

  const object = new ExtendedObject3D().add(mesh);

  if (position) {
    object.position.set(position.x, position.y, position.z);
  }

  let timeoutId = 0;

  object.addEventListener("added", () => {
    object.body.checkCollisions = true;
    object.body.on.collision((otherObject, event) => {
      if (!getUserData(otherObject)?.isTurd) {
        return;
      }

      if (event === "start") {
        console.debug(
          "Goal Sphere :: Collision with turd start",
          otherObject,
          event,
        );

        goalMaterial.color = OCCUPIED_COLOR;
        timeoutId = window.setTimeout(
          () =>
            gameContext.eventDispatcher.dispatchEvent({
              type: "chapterFinished",
            }),
          OCCUPATION_TIME,
        );
      } else if (event === "end") {
        console.debug(
          "Goal Sphere :: Collision with turd end",
          otherObject,
          event,
        );
        goalMaterial.color = UNOCCUPIED_COLOR;
        window.clearTimeout(timeoutId);
      }
    });
  });

  object.addEventListener("removed", () => {
    window.clearTimeout(timeoutId);
  });

  return {
    object,
    config: {
      // Sensor only, no collision, does not move
      collisionFlags: CollisionFlags.GHOST | CollisionFlags.STATIC,
      shape: CollisionShape.CONVEX_MESH,
      // Only check for collisions with turds
      collisionMask: CollisionGroup.TURD,
    },
  };
};
