import { ExtendedObject3D } from "@enable3d/ammo-physics";
import { AddExistingConfig } from "@enable3d/common/dist/types";
import { loadModel } from "../assets";
import { GameContext } from "../gameContext";
import { Euler, Vector3 } from "three";
import { getUserData } from "./userData";

export const CollisionFlags = {
  DYNAMIC: 0,
  STATIC: 1,
  KINEMATIC: 2,
  GHOST: 4,
} as const;

export const CollisionShape = {
  CONVEX_MESH: "convexMesh",
  CONCAVE_MESH: "concaveMesh",
  CAPSULE: "capsule",
  SPHERE: "sphere",
  CYLINDER: "cylinder",
  BOX: "box",
} as const;

export const CollisionGroup = {
  TURD: 1,
} as const;

export const ActivationState = {
  ACTIVE: 1,
  ISLAND_SLEEPING: 2,
  WANTS_DEACTIVATION: 3,
  DISABLE_DEACTIVATION: 4,
  DISABLE_SIMULATION: 5,
} as const;

export interface Entity {
  object: ExtendedObject3D;
  config?: AddExistingConfig;
}

export interface DefaultEntityProps {
  position?: Vector3;
  scale?: Vector3;
  rotation?: Euler;
  transparent?: boolean;
}

export type EntityCreator<T = {}> = (
  gameContext: GameContext,
  entityProps: T & DefaultEntityProps,
) => Promise<Entity>;

export const createExtendedObject3DFromModel = async (
  modelFile: string,
  props?: DefaultEntityProps,
): Promise<ExtendedObject3D> => {
  const object = new ExtendedObject3D().add((await loadModel(modelFile)).scene);

  if (props?.position) {
    object.position.set(props.position.x, props.position.y, props.position.z);
  }

  if (props?.scale) {
    object.scale.set(props.scale.x, props.scale.y, props.scale.z);
  }

  if (props?.rotation) {
    object.rotation.set(
      props.rotation.x,
      props.rotation.y,
      props.rotation.z,
      props.rotation.order,
    );
  }

  if (props?.transparent && object.material) {
    if ("length" in object.material) {
      object.material.forEach((m) => (m.transparent = true));
    } else {
      object.material.transparent = true;
    }
  }

  return object;
};

export type CollisionCallback = (
  otherObject: ExtendedObject3D,
  event: "start" | "collision" | "end",
) => void;

export const createScoreCollisionCallback = (
  gameContext: GameContext,
  score: number,
): CollisionCallback => {
  let scoreGranted = false;

  return (otherObject, event) => {
    if (
      !scoreGranted &&
      event === "start" &&
      getUserData(otherObject)?.isTurd
    ) {
      gameContext.eventDispatcher.dispatchEvent({ type: "scoreEarned", score });
      scoreGranted = true;
    }
  };
};
