import { useRef } from "react";
import { useGameContext } from "../context/GameContextContext";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Table } from "react-bootstrap";
import { Vector3 } from "three";
import { createTurd } from "../entity/turd";
import { useDebug } from "../context/DebugContext";
import { displayInterstitial } from "../ads";

export const DebugMenu = () => {
  const gameContext = useGameContext();
  const { enabled } = useDebug();

  const orbitControlsRef = useRef<OrbitControls | null>(null);

  const logGameContext = () => {
    console.debug("DebugMenu :: Game context", gameContext);

    // Camera
    const cameraWorldPosition = gameContext.camera.getWorldPosition(
      new Vector3(),
    );
    const cameraWorldDirection = gameContext.camera.getWorldDirection(
      new Vector3(),
    );
    const cameraLookAt = cameraWorldPosition.clone().add(cameraWorldDirection);
    console.debug(
      "DebugMenu :: Camera",
      "Position",
      cameraWorldPosition,
      "Direction",
      cameraWorldDirection,
      "Look At",
      cameraLookAt,
    );
  };

  // TODO: expose debug functions to window
  // @ts-ignore
  window["logGameContext"] = logGameContext;

  const fireTurd = async () => {
    const cameraDirection = gameContext.camera.getWorldDirection(new Vector3());
    const turd = await createTurd(gameContext, {
      position: gameContext.camera.position,
      velocity: cameraDirection,
    });
    gameContext.addEntity(turd);
  };

  const enableOrbitControls = (enabled: boolean) => {
    orbitControlsRef.current?.dispose();

    if (enabled) {
      orbitControlsRef.current = new OrbitControls(
        gameContext.camera,
        document.body,
      );
      orbitControlsRef.current.listenToKeyEvents(window);
    }
  };

  if (!enabled) {
    return null;
  }

  return (
    <div style={{ position: "absolute", bottom: 0, right: 0 }}>
      <Table striped={true} bordered={true}>
        <tbody>
          <tr>
            <td>Control Camera</td>
            <td>
              <input
                type={"checkbox"}
                onChange={(e) => enableOrbitControls(e.target.checked)}
              />
            </td>
          </tr>
          <tr>
            <td>Debug Physics</td>
            <td>
              <input
                type={"checkbox"}
                onChange={(e) =>
                  e.target.checked
                    ? gameContext.physics.debug?.enable()
                    : gameContext.physics.debug?.disable()
                }
              />
            </td>
          </tr>
          <tr>
            <td>Log Game Context</td>
            <td>
              <input type={"button"} value={"Print"} onClick={logGameContext} />
            </td>
          </tr>
          <tr>
            <td>Fire Turd</td>
            <td>
              <input type={"button"} value={"Fire"} onClick={fireTurd} />
            </td>
          </tr>
          <tr>
            <td>Finish Chapter</td>
            <td>
              <input
                type={"button"}
                value={"Finish Chapter"}
                onClick={() =>
                  gameContext.eventDispatcher.dispatchEvent({
                    type: "chapterFinished",
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Fail Chapter</td>
            <td>
              <input
                type={"button"}
                value={"Fail Chapter"}
                onClick={() =>
                  gameContext.eventDispatcher.dispatchEvent({
                    type: "chapterFailed",
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Display interstitial</td>
            <td>
              <input
                type={"button"}
                value={"Display"}
                onClick={displayInterstitial}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
