import { useGameContext } from "../context/GameContextContext";
import { useEffect, useState } from "react";
import { Clock, WebGLRenderer } from "three";
import { DebugMenu } from "./DebugMenu";

export const SceneCanvas = () => {
  const gameContext = useGameContext();

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const clock = new Clock();
    const renderer = new WebGLRenderer({ canvas });

    // Resize camera
    const resize = () => {
      console.debug(
        "SceneCanvas :: Resized",
        window.innerWidth,
        window.innerHeight,
      );

      // Resize renderer
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);

      // Resize camera
      gameContext.camera.aspect = window.innerWidth / window.innerHeight;
      gameContext.camera.setViewOffset(
        window.innerWidth,
        window.innerHeight,
        0,
        0,
        window.innerWidth,
        window.innerHeight,
      );
      gameContext.camera.updateMatrix();
      console.debug("SceneCanvas :: Camera resized", gameContext.camera);
    };

    resize();

    window.addEventListener("resize", resize);

    // Render loop
    const renderFrame = () => {
      gameContext.physics.update(clock.getDelta() * 1000);
      gameContext.physics.updateDebugger();
      renderer.render(gameContext.scene, gameContext.camera);
      requestAnimationFrame(renderFrame);
    };

    requestAnimationFrame(renderFrame);

    // Clean up
    return () => {
      canvas.removeEventListener("resize", resize);
      renderer.dispose();
    };
  }, [canvas, gameContext]);

  return (
    <>
      <DebugMenu />
      <canvas
        ref={setCanvas}
        style={{ position: "absolute", left: 0, top: 0, zIndex: -1 }}
      />
    </>
  );
};
