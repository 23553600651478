import { Chapter, World } from "../../world";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDebug } from "../../context/DebugContext";
import { useNavigate } from "react-router-dom";
import { useGameContext } from "../../context/GameContextContext";
import { playSound } from "../../sound";
import { routes } from "../index";
import { GameEventListener } from "../../gameContext";
import { Vector2, Vector3 } from "three";
import { createTurd } from "../../entity/turd";
import { SwipeHandler, useSwipeGesture } from "../../hook/useSwipeGesture";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Lives } from "../../component/Lives";
import { GameState } from "./";

const PREPARE_TIME = 2000;

const calculateScore = (timeDiff: number) => {
  return Math.max(PREPARE_TIME - timeDiff, 0) / 2;
};

interface ScoreInfoProps {
  score: number;
}

const ScoreInfo = ({ score }: ScoreInfoProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "1rem",
        right: "1rem",
      }}
    >
      <div style={{ opacity: 0.66 }}>{score}</div>
    </div>
  );
};

const SwipeToFlingInfo = () => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "4rem",
      }}
      className={"fade-in-animation"}
    >
      <div className={"bounce-animation"}>
        <div style={{ opacity: 0.66 }}>
          <FormattedMessage id={"swipe-to-fling-turd"} />
        </div>
      </div>
    </div>
  );
};

interface PlayingScreenProps {
  world: World;
  chapter: Chapter;
  reloadChapter: () => void;
  gameState: GameState;
  setGameState: Dispatch<SetStateAction<GameState>>;
}

export const PlayingScreen = ({
  world,
  chapter,
  reloadChapter,
  gameState,
  setGameState,
}: PlayingScreenProps) => {
  const { enabled: debugEnabled } = useDebug();
  const navigate = useNavigate();
  const [turdFired, setTurdFired] = useState(false);
  const turdFiredAtRef = useRef<Date | null>(null);
  const mountedSinceRef = useRef(new Date());
  const chapterFinishedRef = useRef(false);
  const transitionToNextChapterTimeoutIdRef = useRef<undefined | number>(
    undefined,
  );
  const gameContext = useGameContext();

  useEffect(
    () => () => {
      window.clearTimeout(transitionToNextChapterTimeoutIdRef.current);
    },
    [],
  );
  const failChapter = useCallback(() => {
    // Chapter already finished
    if (chapterFinishedRef.current) {
      return;
    }

    playSound("toilet-flush.mp3", { volume: 0.5 });
    const newLives = gameState.lives - 1;

    setGameState({
      ...gameState,
      lives: newLives,
    });

    if (newLives <= 0) {
      // TODO: navigate to "lost" screen
      navigate(routes.worldSelection.render());
    } else {
      reloadChapter();
      setTurdFired(false);
    }
  }, [reloadChapter, gameState, setGameState, navigate]);

  // Register game event listeners
  useEffect(() => {
    // Chapter failed events
    gameContext.eventDispatcher.addEventListener("chapterFailed", failChapter);

    // Chapter finished events
    const chapterFinishedListener = () => {
      chapterFinishedRef.current = true;
      playSound("oh_yeah.wav", { playbackRate: 0.9 + Math.random() * 0.1 });
      const timeDiff = turdFiredAtRef.current
        ? turdFiredAtRef.current.getTime() - mountedSinceRef.current.getTime()
        : 0;
      const addedScore = turdFiredAtRef.current ? calculateScore(timeDiff) : 0;

      // TODO: add remaining lives to score, when theres no chapter left

      setGameState({
        ...gameState,
        earnedScore: gameState.earnedScore + addedScore,
      });

      if (transitionToNextChapterTimeoutIdRef.current === undefined) {
        transitionToNextChapterTimeoutIdRef.current = window.setTimeout(() => {
          console.debug("PlayingScreen :: Timeout");

          setGameState((g) => ({
            ...g,
            chapterIndex: g.chapterIndex + 1,
          }));
        }, 1000);
      }
    };
    gameContext.eventDispatcher.addEventListener(
      "chapterFinished",
      chapterFinishedListener,
    );

    // Earn score events
    const earnScoreListener: GameEventListener<"scoreEarned"> = (event) => {
      setGameState({
        ...gameState,
        earnedScore: gameState.earnedScore + event.score,
      });
    };
    gameContext.eventDispatcher.addEventListener(
      "scoreEarned",
      earnScoreListener,
    );

    return () => {
      gameContext.eventDispatcher.removeEventListener(
        "chapterFailed",
        failChapter,
      );
      gameContext.eventDispatcher.removeEventListener(
        "chapterFinished",
        chapterFinishedListener,
      );
      gameContext.eventDispatcher.removeEventListener(
        "scoreEarned",
        earnScoreListener,
      );
    };
  }, [
    navigate,
    reloadChapter,
    gameContext,
    world.worldId,
    failChapter,
    gameState,
    setGameState,
  ]);

  // Turd fire listeners
  const spawnTurdEntity = useCallback(
    async (velocity: Vector3, position: Vector3) => {
      const turdEntity = await createTurd(gameContext, { velocity, position });
      gameContext.addEntity(turdEntity);

      const fartIndex = Math.ceil(Math.random() * 16);
      playSound(`fart/fart_${fartIndex}.wav`);

      console.log("In Level Route :: Spawn turd", velocity, position);

      // Flying stains
      /*const speed = velocity.length();
        for (let i = 0; i < 5; i++) {
            //const direction = gameContext.camera.getWorldDirection(new Vector3());
            //const perpendicular = gameContext.camera.up.clone().cross(direction);

            const sign = Math.random() > 0.5 ? -1 : 1;
            const v = new Vector3().copy(direction)
                 .addScaledVector(perpendicular, sign * (0.1 + Math.random()))
                 .addScaledVector(gameContext.camera.up, 0.75 + Math.random())
                 .multiplyScalar(speed / 5)
            gameContext.addEntity(await createFlyingStain(gameContext, {position, velocity: v}))
        }*/
    },
    [gameContext],
  );

  const handleSwipe = useCallback<SwipeHandler>(
    ({ diffClientX, diffClientY, diffTime, clientXStart, clientYStart }) => {
      if (turdFired || debugEnabled) {
        return;
      }

      setTurdFired(true);
      turdFiredAtRef.current = new Date();

      console.log(
        "In Level Route :: Handle swipe",
        turdFired,
        diffClientX,
        diffClientY,
        diffTime,
      );

      // Calculate velocity
      const cameraDirection = gameContext.camera.getWorldDirection(
        new Vector3(),
      );
      const perpendicularDirection = cameraDirection
        .clone()
        .cross(gameContext.camera.up);
      const upDirection = perpendicularDirection.clone().cross(cameraDirection);

      const diffClientScreenFraction = new Vector2(
        diffClientX / window.innerWidth,
        diffClientY / window.innerHeight,
      );
      const seconds = diffTime / 1000;
      const speed = Math.min(
        (diffClientScreenFraction.length() * 3) / seconds,
        20,
      );

      const velocity = cameraDirection
        .clone()
        .add(
          upDirection
            .clone()
            .multiplyScalar(0.1 + diffClientScreenFraction.y * 1.5),
        )
        .add(
          perpendicularDirection
            .clone()
            .multiplyScalar(-diffClientScreenFraction.x * 2.0),
        )
        .multiplyScalar(speed);

      //const local = new Vector3(clientXStart / window.innerWidth, clientYStart / window.innerHeight, 0);
      const local = new Vector3(
        clientXStart / window.innerWidth - 0.5,
        1.0 - clientYStart / window.innerHeight - 0.5,
        0,
      );
      gameContext.camera.localToWorld(local);

      void spawnTurdEntity(velocity, local);
    },
    [spawnTurdEntity, gameContext.camera, debugEnabled, turdFired],
  );

  useSwipeGesture({ onSwipe: handleSwipe });

  return (
    <>
      <Container
        className={"d-flex flex-column align-items-center unselectable"}
        style={{ marginTop: "1rem" }}
      >
        <h3 style={{ textAlign: "center" }}>
          <FormattedMessage
            id={`worlds.${world.worldId}.chapter.${chapter.chapterId}`}
          />
        </h3>
        <Lives lives={gameState.lives ?? 0} maxLives={world.lives} />
        {!turdFired && <SwipeToFlingInfo />}
        <ScoreInfo score={gameState.earnedScore} />
      </Container>
    </>
  );
};
