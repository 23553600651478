import {
  CollisionFlags,
  CollisionGroup,
  CollisionShape,
  createExtendedObject3DFromModel,
  Entity,
  EntityCreator,
} from "./index";
import { Vector3 } from "three";
import { playSound } from "../sound";
import { createStain } from "./stain";
import { setUserData } from "./userData";

const IDLE_TIME = 4500;

export interface TurdProps {
  velocity: Vector3;
}

export const createTurd: EntityCreator<TurdProps> = async (
  gameContext,
  entityProps,
) => {
  const object = await createExtendedObject3DFromModel("turd.glb", entityProps);
  object.addEventListener("added", () => {
    setUserData(object, {
      isTurd: true,
    });

    // Update physic props
    object.body.setBounciness(0.33);
    object.body.setFriction(0.5);
    object.body.ammo.setRollingFriction(0.15);
    object.body.setDamping(0.04, 0.2);

    // A turd is a small and fast projectile
    // Therefore we use continuous collision detection
    object.body.setCcdMotionThreshold(0.05);
    object.body.setCcdSweptSphereRadius(0.05);

    // Some splashiness :)
    object.body.on.collision((otherObject, event) => {
      if (
        (otherObject.body.getCollisionFlags() & CollisionFlags.GHOST) ===
        CollisionFlags.GHOST
      ) {
        // Skip ghost objects
        return;
      }

      if (event === "start") {
        const speed = new Vector3(
          object.body.velocity.x,
          object.body.velocity.y,
          object.body.velocity.z,
        ).length();

        if (speed > 0.75) {
          // Play sound
          const soundIndex = Math.ceil(Math.random() * 5); // Random integer between 1 and 16 (both inclusive)
          playSound(`wet_splat/splat_${soundIndex}.wav`, { volume: 0.5 });

          // Create stains only on static objects
          const scaleFactor = Math.sqrt(speed) * 0.66;
          if (
            (otherObject.body.getCollisionFlags() & CollisionFlags.STATIC) ===
            CollisionFlags.STATIC
          ) {
            createStain(gameContext, {
              position: new Vector3(
                object.body.position.x,
                object.body.position.y,
                object.body.position.z,
              ),
              scale: new Vector3(scaleFactor, scaleFactor, scaleFactor),
            }).then((stainEntity) => gameContext.addEntity(stainEntity));
          }
        }
      }
    });

    // Fire in the hole!#
    const velocity = entityProps.velocity;
    object.body.setVelocity(velocity.x, velocity.y, velocity.z);
    object.body.setAngularVelocity(velocity.x, velocity.y, velocity.z);
  });

  const entity: Entity = {
    object,
    config: {
      addChildren: false,
      collisionFlags: CollisionFlags.DYNAMIC,
      collisionGroup: CollisionGroup.TURD,
      shape: CollisionShape.CAPSULE,
      radius: 0,
      height: 0.09,
      mass: 10.0,
      margin: 0,
    },
  };

  let timeoutId = 0;

  object.addEventListener("added", () => {
    timeoutId = window.setTimeout(
      () =>
        gameContext.eventDispatcher.dispatchEvent({
          type: "chapterFailed",
        }),
      IDLE_TIME,
    );
  });

  object.addEventListener("removed", () => {
    window.clearTimeout(timeoutId);
  });

  return entity;
};
