import React, { useCallback, useEffect, useId, useState } from "react";
import { useParams } from "react-router-dom";
import { useGameContext } from "../../context/GameContextContext";
import { worlds } from "../../world";
import { playBackgroundSound, playDefaultBackgroundSound } from "../../sound";
import { displayInterstitial, INTERSTITIAL_PROBABILITY } from "../../ads";
import { Mutex } from "async-mutex";
import { PlayingScreen } from "./PlayingScreen";
import { LoadingScreen } from "./LoadingScreen";
import { LevelResultsScreen } from "./LevelResultsScreen";

export interface GameState {
  lives: number;
  earnedScore: number;
  chapterIndex: number;
}

const mutex = new Mutex();

export const PlayWorldRoute = () => {
  // Extract level parameters
  const { worldId } = useParams();
  const world = worlds.find((w) => w.worldId === worldId);

  if (!world) {
    throw new Error(`World ${worldId} not found`);
  }

  const id = useId();
  const [loading, setLoading] = useState(true);
  const [gameState, setGameState] = useState<GameState>({
    chapterIndex: 0,
    lives: world.lives,
    earnedScore: 0,
  });
  const gameContext = useGameContext();

  const chapter =
    gameState.chapterIndex < world.chapters.length
      ? world.chapters[gameState.chapterIndex]
      : null;

  console.debug("Play Chapter Route :: Render", id, world, loading);

  const reloadChapter = useCallback(() => {
    void mutex.runExclusive(async () => {
      if (!chapter) {
        return;
      }

      console.debug("Play Chapter Route :: Reloading chapter", chapter);
      setLoading(true);

      if (Math.random() < INTERSTITIAL_PROBABILITY) {
        displayInterstitial();
      }

      // Clear scene
      gameContext.clear();

      // Load chapter
      await chapter.prepareGameContext(gameContext).then(() => {
        setLoading(false);
      });
    });
  }, [gameContext, chapter]);

  // Load chapter on chapter change
  useEffect(() => {
    reloadChapter();
  }, [reloadChapter]);

  // Play sound
  useEffect(() => {
    playBackgroundSound(world.soundFile, world.soundVolume);

    return () => {
      playDefaultBackgroundSound();
    };
  }, [world]);

  // Clear game context on unmount
  useEffect(() => {
    return () => {
      gameContext.clear();
    };
  }, [gameContext]);

  if (!chapter) {
    return <LevelResultsScreen gameState={gameState} />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PlayingScreen
      world={world}
      chapter={chapter}
      reloadChapter={reloadChapter}
      gameState={gameState}
      setGameState={setGameState}
    />
  );
};
