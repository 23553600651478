import {
  ActivationState,
  CollisionShape,
  createExtendedObject3DFromModel,
  createScoreCollisionCallback,
  Entity,
  EntityCreator,
} from "./index";
import { Vector3 } from "three";

export interface ToiletPaperProps {
  position: Vector3;
}

export const createToiletPaper: EntityCreator<ToiletPaperProps> = async (
  gameContext,
  entityProps,
) => {
  const object = await createExtendedObject3DFromModel("toilet_paper.glb", {
    ...entityProps,
    scale: entityProps.scale ?? new Vector3(0.1, 0.1, 0.1),
  });
  const entity: Entity = {
    object,
    config: {
      shape: CollisionShape.CYLINDER,
      ignoreScale: true,
      radius: 0.1,
      height: 0.18,
      mass: 1.0,
    },
  };

  object.addEventListener("added", () => {
    // Update physic props
    object.body.ammo.setFriction(0.5);
    object.body.ammo.setRollingFriction(0.01);
    object.body.ammo.setDamping(0.02, 0.0);
    object.body.ammo.setRestitution(0.0);
    object.body.ammo.forceActivationState(ActivationState.ISLAND_SLEEPING);

    const scoreCallback = createScoreCollisionCallback(gameContext, 50);

    object.body.on.collision((otherObject, event) => {
      scoreCallback(otherObject, event);
    });
  });

  return entity;
};
