import { GameContextPreparer, World } from "./index";
import { AmbientLight, Color, DirectionalLight, Euler, Vector3 } from "three";
import { createStaticEntity } from "../entity/staticEntity";
import { createGoalBox } from "../entity/goalBox";
import { createWeirdDude } from "../entity/weirdDude";

const loadBaseWorld: GameContextPreparer = async (gameContext) => {
  gameContext.scene.add(new AmbientLight(new Color("white"), 0.1));

  const directionalLight = new DirectionalLight(new Color("white"), 2);
  directionalLight.position.set(-10, 10, -10);
  directionalLight.lookAt(0, 0, 0);
  gameContext.scene.add(directionalLight);
  gameContext.scene.background = new Color("#010f20");

  gameContext.addEntity(
    await createStaticEntity(gameContext, {
      modelFile: "space_ship.glb",
      position: new Vector3(0, 0, 10),
      rotation: new Euler(0, Math.PI * 0.5, 0),
    }),
  );
};

export const space: World = {
  worldId: "space",
  lives: 5,
  soundFile: "deep_space.mp3",
  soundVolume: 0.2,
  chapters: [
    {
      chapterId: "into-the-goal",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(0, 0.9 * 0.8, 13),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(-1, 0.9 * 0.7, 14),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.7, 0.7, 0.7),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(1, 0.9 * 0.9, 14),
            rotation: new Euler(0, Math.PI * 1.1, 0),
            scale: new Vector3(0.9, 0.9, 0.9),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(0, 0.8, 15.5),
            scale: new Vector3(5.2, 2.1, 1.0),
            idleTurd: false,
          }),
        );

        // Set camera
        gameContext.camera.position.set(0, 1.18, 0);
        gameContext.camera.lookAt(0, 0.0, 15);
        gameContext.camera.updateMatrix();

        gameContext.physics.setGravity(0, 0, 0);
      },
    },
  ],
};
