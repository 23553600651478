import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchText } from "../text";

export const DisclaimerRoute = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    fetchText("disclaimer").then((t) => setText(t));
  }, []);

  return (
    <>
      <Container className={"d-flex flex-column align-items-center"}>
        <h1>
          <FormattedMessage id={"disclaimer"} />
        </h1>
        <p style={{ whiteSpace: "preserve-breaks" }}>{text}</p>
      </Container>
    </>
  );
};
