import { Cache } from "three";

import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

Cache.enabled = true;

// Draco / compression
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("lib/");

// Gltf loader
const gltfLoader = new GLTFLoader();
gltfLoader.dracoLoader = dracoLoader;

console.debug("Assets :: Gltf loader", gltfLoader);

export const loadModel = (file: string) =>
  new Promise<GLTF>((resolve, reject) => {
    console.debug("Assets :: Loading model", file);

    gltfLoader.load(`/models/${file}`, resolve, (event) => {}, reject);
  });
