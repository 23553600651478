import { createContext, useContext } from "react";
import { GameContext } from "../gameContext";

export const GameContextContext = createContext<GameContext | null>(null);

export const useGameContext = (): GameContext => {
  const gameContext = useContext(GameContextContext);

  if (gameContext === null) {
    throw new Error("Game context not provided");
  }

  return gameContext;
};
