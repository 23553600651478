import {
  CollisionFlags,
  CollisionShape,
  createExtendedObject3DFromModel,
  EntityCreator,
} from "./index";

export interface StaticEntityProps {
  modelFile: string;
}

export const createStaticEntity: EntityCreator<StaticEntityProps> = async (
  _,
  entityProps,
) => {
  const object = await createExtendedObject3DFromModel(
    entityProps.modelFile,
    entityProps,
  );
  object.addEventListener("added", () => {
    // Bounciness on a target object is required for a source object to be able to bounce in the first place
    object.body.setBounciness(1);
    object.body.ammo.setRollingFriction(0.5);
  });
  return {
    object,
    config: {
      addChildren: false,
      mass: 0,
      collisionFlags: CollisionFlags.STATIC,
      shape: CollisionShape.CONCAVE_MESH,
    },
  };
};
