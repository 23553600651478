import { GameContextPreparer, World } from "./index";
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Euler,
  HemisphereLight,
  PointLight,
  Vector3,
} from "three";
import { createStaticEntity } from "../entity/staticEntity";
import { createGoalBox } from "../entity/goalBox";
import { createWeirdDude } from "../entity/weirdDude";

const loadBaseWorld: GameContextPreparer = async (gameContext) => {
  gameContext.scene.add(new AmbientLight(new Color("white"), 2));
  const pointLight = new PointLight(new Color("lightsalmon"), 0.25, 10, 1.5);
  pointLight.position.set(0, 3, 1);
  gameContext.scene.add(pointLight);

  const directionalLight = new DirectionalLight(new Color("white"), 0.75);
  directionalLight.position.set(-10, 10, -10);
  directionalLight.lookAt(0, 0, 0);
  gameContext.scene.add(directionalLight);
  gameContext.scene.add(
    new HemisphereLight(new Color("cyan"), new Color("black"), 2),
  );
  gameContext.scene.background = new Color("cyan");

  gameContext.addEntity(
    await createStaticEntity(gameContext, { modelFile: "soccer_field.glb" }),
  );
  gameContext.addEntity(
    await createStaticEntity(gameContext, {
      modelFile: "soccer_goal.glb",
      position: new Vector3(0, 0, 15),
      scale: new Vector3(2, 1, 1),
      rotation: new Euler(0, Math.PI, 0),
    }),
  );
};

export const soccerField: World = {
  worldId: "soccerField",
  lives: 5,
  soundFile: "stadium.mp3",
  soundVolume: 0.2,
  chapters: [
    {
      chapterId: "into-the-goal",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(0, 0.9 * 0.8, 13),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(-1, 0.9 * 0.7, 14),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.7, 0.7, 0.7),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(1, 0.9 * 0.9, 14),
            rotation: new Euler(0, Math.PI * 1.1, 0),
            scale: new Vector3(0.9, 0.9, 0.9),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(0, 0.8, 15.5),
            scale: new Vector3(5.2, 2.1, 1.0),
            idleTurd: false,
          }),
        );

        // Set camera
        gameContext.camera.position.set(0, 1.18, 10);
        gameContext.camera.lookAt(0, 0.0, 15);
        gameContext.camera.updateMatrix();

        gameContext.physics.setGravity(-1.5, -9.81, 0);
      },
    },
    {
      chapterId: "into-the-goal-2",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(0.8, 0.9 * 0.8, 13),
            rotation: new Euler(0, Math.PI * 0.94, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(-0.4, 0.9 * 0.7, 13),
            rotation: new Euler(0, Math.PI * 0.8, 0),
            scale: new Vector3(0.7, 0.7, 0.7),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(1.8, 0.9 * 0.9, 14),
            rotation: new Euler(0, Math.PI * 0.9, 0),
            scale: new Vector3(0.9, 0.9, 0.9),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(0, 0.8, 15.5),
            scale: new Vector3(5.2, 2.1, 1.0),
            idleTurd: false,
          }),
        );

        // Set camera
        gameContext.camera.position.set(3, 1.18, 8);
        gameContext.camera.lookAt(1, 0.0, 15);
        gameContext.camera.updateMatrix();

        gameContext.physics.setGravity(-1.5, -9.81, 0);
      },
    },
    {
      chapterId: "into-the-goal-3",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(0, 0.9 * 0.8, 13),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(-1.9, 0.9 * 0.7, 8.0),
            rotation: new Euler(0, Math.PI * 1.1, 0),
            scale: new Vector3(0.7, 0.7, 0.7),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(1, 0.9 * 0.9, 14),
            rotation: new Euler(0, Math.PI * 1.1, 0),
            scale: new Vector3(0.9, 0.9, 0.9),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(0, 0.8, 15.5),
            scale: new Vector3(5.2, 2.1, 1.0),
            idleTurd: false,
          }),
        );

        // Set camera
        gameContext.camera.position.set(-2, 1.18, 6);
        gameContext.camera.lookAt(-1, 0.0, 15);
        gameContext.camera.updateMatrix();

        gameContext.physics.setGravity(-1.5, -9.81, 0);
      },
    },
    {
      chapterId: "into-the-goal-4",
      prepareGameContext: async (gameContext) => {
        await loadBaseWorld(gameContext);

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(0, 0.9 * 0.8, 13),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.8, 0.8, 0.8),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(-1, 0.9 * 0.7, 14),
            rotation: new Euler(0, Math.PI * 0.98, 0),
            scale: new Vector3(0.7, 0.7, 0.7),
          }),
        );

        gameContext.addEntity(
          await createWeirdDude(gameContext, {
            position: new Vector3(1, 0.9 * 0.9, 14),
            rotation: new Euler(0, Math.PI * 1.1, 0),
            scale: new Vector3(0.9, 0.9, 0.9),
          }),
        );

        // Goal
        gameContext.addEntity(
          await createGoalBox(gameContext, {
            position: new Vector3(0, 0.8, 15.5),
            scale: new Vector3(5.2, 2.1, 1.0),
            idleTurd: false,
          }),
        );

        // Set camera
        gameContext.camera.position.set(0, 1.18, 0);
        gameContext.camera.lookAt(0, 0.0, 15);
        gameContext.camera.updateMatrix();

        gameContext.physics.setGravity(-1.5, -9.81, 0);
      },
    },
  ],
};
