import {
  createExtendedObject3DFromModel,
  Entity,
  EntityCreator,
} from "./index";

export const createStain: EntityCreator = async (gameContext, entityProps) => {
  const object = await createExtendedObject3DFromModel("turd.glb", entityProps);
  object.scale.y *= 0.25;
  object.rotation.set(
    Math.random() * 360,
    Math.random() * 360,
    Math.random() * 360,
  );
  const entity: Entity = {
    object,
  };

  return entity;
};
