import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { routes } from "../index";
import React from "react";
import { GameState } from "./";

interface LevelResultsScreenProps {
  gameState: GameState;
}

export const LevelResultsScreen = ({ gameState }: LevelResultsScreenProps) => {
  const navigate = useNavigate();

  return (
    <Container
      style={{ height: "100%" }}
      className={"d-flex flex-column justify-content-center align-items-center"}
    >
      <h1>
        <FormattedMessage id={"world.finished"} />
      </h1>
      <h3>
        <FormattedMessage id={"score"} />: {gameState.earnedScore}
      </h3>
      <Row style={{ width: "100%" }}>
        <Col />
        <Col xs={6}>
          <Button
            size={"lg"}
            className={"mt-4"}
            style={{ width: "100%", height: "4rem", fontSize: "1.5rem" }}
            onClick={() => navigate(routes.worldSelection.render())}
          >
            <FormattedMessage id={"play.again"} />
          </Button>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};
