import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React, { useId } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "./index";
import { World, worlds } from "../world";

interface WorldCardProps {
  world: World;
}

const WorldCard = ({ world }: WorldCardProps) => {
  const navigate = useNavigate();

  const playWorld = () => {
    navigate(routes.playWorld.render(world.worldId));

    // This is necessary to fix ammo js out-of-memory issues
    window.location.reload();
  };

  return (
    <Card onClick={playWorld}>
      <Card.Img variant="top" src={`/images/preview_${world.worldId}.png`} />
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>
          <FormattedMessage id={`worlds.${world.worldId}`} />
        </Card.Title>
        <Button
          size={"lg"}
          style={{ width: "100%" }}
          className={"mt-2"}
          variant="primary"
        >
          <FormattedMessage id={"play"} />
        </Button>
      </Card.Body>
    </Card>
  );
};

export const LevelSelectionRoute = () => {
  const id = useId();
  console.debug("Level Selection Route :: Render", id);

  return (
    <>
      <Container>
        <Row>
          <h1 style={{ textAlign: "center", marginTop: "1rem" }}>
            <FormattedMessage id={"select.world"} />
          </h1>
        </Row>
        <Row xs={1} md={2} lg={4}>
          {worlds.map((world, index) => (
            <Col key={index} className={"mb-4"}>
              <WorldCard world={world} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
