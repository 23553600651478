import {
  Color,
  EventDispatcher,
  EventListener,
  PerspectiveCamera,
  Scene,
} from "three";
import { AmmoPhysics, ExtendedObject3D } from "@enable3d/ammo-physics";
import { Entity } from "./entity";

export interface GameEvents {
  chapterFinished: {};
  chapterFailed: {};
  scoreEarned: {
    score: number;
  };
}

export type GameEventListener<T extends keyof GameEvents> = EventListener<
  GameEvents[T],
  T,
  EventDispatcher<GameEvents>
>;

export interface GameContextActions {
  addEntity: (entity: Entity) => void;
  removeEntity: (entity: Entity) => void;
  clear: () => void;
}

export interface GameContext extends GameContextActions {
  scene: Scene;
  camera: PerspectiveCamera;
  physics: AmmoPhysics;
  eventDispatcher: EventDispatcher<GameEvents>;
}

export const createGameContext = (): GameContext => {
  const scene = new Scene();
  scene.background = new Color("black");

  const camera = new PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000,
  );
  camera.position.set(0, 0, 0);
  camera.lookAt(0, 0, 1);

  const physics = new AmmoPhysics(scene, { softBodies: false });

  return {
    scene,
    camera,
    physics,
    addEntity: (entity) => {
      if (entity.config) {
        physics.add.existing(entity.object, entity.config);
      }
      scene.add(entity.object);
    },
    removeEntity: (entity) => {
      forceDestroyRigidBody(physics, entity.object);
      scene.remove(entity.object);
    },
    clear: () => {
      // Reset scene
      scene.background = new Color("black");
      scene.fog = null;

      // Reset physics

      // Remove all entities
      physics.rigidBodies.forEach((rigidBody) =>
        forceDestroyRigidBody(physics, rigidBody),
      );
      scene.clear();
      physics.rigidBodies.forEach((rigidBody) =>
        forceDestroyRigidBody(physics, rigidBody),
      );
    },
    eventDispatcher: new EventDispatcher<GameEvents>(),
  };
};

const forceDestroyRigidBody = (
  physics: AmmoPhysics,
  rigidBody: ExtendedObject3D,
) => {
  rigidBody.children.forEach((c) => forceDestroyRigidBody(physics, c));

  try {
    physics.destroy(rigidBody.body);
  } catch (e) {
    // Ignore
  }

  try {
    physics.destroy(rigidBody);
  } catch (e) {
    // Ignore
  }

  // Destroy collision shape
  const collisionShape = rigidBody.body?.ammo?.getCollisionShape();

  if (collisionShape) {
    try {
      Ammo.destroy(collisionShape);
    } catch (e) {
      // Ignore
    }
  }
};
